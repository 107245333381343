import axios from "axios";
import React, { useEffect, useState, useCallback } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import ReactPlayer from "react-player";
import {
	EventMessage,
	EventMessageFromParentRequest,
	GetStatusEventMessageResponse,
	IVideoData,
} from "./types";

/**
 * 指定のミリ秒まつ
 * @param {millisecond} millisecond 待つ時間（ミリ秒）
 */
const waitFor = async (millisecond: number) => {
	console.log(`wait for ${millisecond}ms...`);
	await new Promise<void>((resolve) => {
		setTimeout(() => resolve(), millisecond);
	});
};

const getPlaylist = async (
	videoId: string,
	millisecond: number,
): Promise<IVideoData> => {
	return axios.get(`/api/video/${videoId}`).then(async (response) => {
		if (response.data === undefined) {
			throw new Error("unknown error...");
		}
		switch (response.data?.status) {
			case "finishedConversion":
				// コンバート終了時は取得されたデータを返却
				return response.data;
			case "errorPleaseRetry":
				// エラー処理。エラーハンドリングはコール元で行う
				throw new Error(response.data);
			default:
				// エラー、完了以外はconvert待ち(or download待ち)
				await waitFor(millisecond);
				return getPlaylist(videoId, millisecond);
		}
	});
};

const clientServer = process.env.REACT_APP_DOMAIN_NAME ?? "";
const defaultRetryMillisecond = 1000;
const getRetryMillisecond = (): number => {
	if (process.env.RETRY_MILLISECOND != null) return defaultRetryMillisecond;
	const tmp = Number(process.env.RETRY_MILLISECOND);
	if (isNaN(tmp)) return defaultRetryMillisecond;
	return tmp;
};
const retryMillisecond = getRetryMillisecond();

const Player = React.memo(() => {
	// defining the initial state for the form
	const { videoId } = useParams();

	const [src, setSrc] = useState("");

	const [searchParams] = useSearchParams();

	const [cookies, setCookie] = useCookies(["token"]);

	const [isThumbnailVisible, setThumbnailVisible] = useState(true);

	const [useControls, setUseControls] = useState(true);

	const [loading, setLoading] = useState(true);

	const [err, setErr] = useState(false);

	const [isPlaying, setPlaying] = useState(false);

	useEffect(() => {
		const element = document.getElementById(
			"whitelist-token",
		) as HTMLInputElement;
		const whitelistToken: string | undefined = element?.value;
		if (videoId == null) return;
		getPlaylist(videoId, retryMillisecond)
			.then((data) => {
				setLoading(false);
				const currentToken =
					whitelistToken ?? searchParams.get("token") ?? cookies.token;
				setSrc(
					data.domain +
						data.playlist +
						`?token=${currentToken}&videoId=${videoId}`,
				);
				console.log(`${videoId}: loaded`);
				window.parent.postMessage(
					{ videoId, type: "loaded" } as EventMessage,
					"*",
				);
			})
			.catch((e) => {
				setErr(true);
				setLoading(false);
				console.log(e);
			});
	}, [cookies.token, searchParams, videoId]);

	const addEventMessage = useCallback(
		(event: MessageEvent<EventMessageFromParentRequest>) => {
			console.log("catch event", event);
			if (event.origin && event.origin !== clientServer) {
				if (typeof event.data !== "object" || "type" in event.data === false)
					return;
				switch (event.data.type) {
					case "cookies":
						setCookie("token", event.data);
						window.location.reload();
						return;
					case "status":
						event.source?.postMessage(
							{
								videoId,
								type: "status",
								loaded: !loading,
								value: event.data.value
							} as GetStatusEventMessageResponse,
							event.origin as any
						);
						return;
					case "pause":
					case "play":
						// thumnailのvisibleも調整
						const isPlay = event.data.type === "play"
						setThumbnailVisible(!isPlay);
						setPlaying(isPlay);
						return;
					case "controls":
						setUseControls(event.data.value);
						return;
					default:
						return;
				}
			}
		},
		[loading, setCookie, videoId],
	);

	/**
	 * 必ず1度のみの登録とする
	 * 仮に再度renderされたときにはeventを削除してから登録するものとする。
	 */
	useEffect(() => {
		window.addEventListener("message", addEventMessage);
		return () => {
			console.log("remove event");
			return window.removeEventListener("message", addEventMessage)
		};
	}, [addEventMessage]);

	const handlePlay = useCallback(() => {
		console.log(`${videoId}: play`);
		window.parent.postMessage({ videoId, type: "start" } as EventMessage, "*");
	}, [videoId]);

	const handleEnded = useCallback(() => {
		console.log(`${videoId}: ended`);
		window.parent.postMessage(
			{ videoId, type: "complete" } as EventMessage,
			"*",
		);
	}, [videoId]);

	const handlePause = useCallback(() => {
		console.log(`${videoId}: pause`);
		setThumbnailVisible(true);
		setPlaying(false);
		window.parent.postMessage({ videoId, type: "pause" } as EventMessage, "*");
	}, [videoId]);
	const handleStart = useCallback(() => {
		console.log(`${videoId}: start`);
		setThumbnailVisible(false);
		setPlaying(true);
		window.parent.postMessage({ videoId, type: "start" } as EventMessage, "*");
	}, [videoId]);
	const handleChange = useCallback(() => {
		if (!useControls) return;
		const changePlaying = !isPlaying;
		console.log(`${videoId}: click change`);
		setThumbnailVisible(!changePlaying);
		setPlaying(changePlaying);
	},[isPlaying, videoId, useControls]);


	return (
		<div onClick={handleChange} className="streaming-player">
			{!loading && (
				<>
					{useControls && (
						<div onClick={handleChange} className="streaming-player__thumbnail">
							{isThumbnailVisible && (
								<span className="play-icon"><i className="bi bi-play-fill" /></span>
							)}
							{useControls && !isThumbnailVisible && (
								<span className="pause-icon"><i className="bi bi-pause-fill" /></span>
							)}
						</div>
					)}
					<ReactPlayer
						className="streaming-player__main"
						url={src}
						width="100%"
						height="100%"
						controls={false}
						pip={false}
						playing={isPlaying}
						playsinline={true}
						onStart={handleStart}
						onEnded={handleEnded}
						onPlay={handlePlay}
						onPause={handlePause}
					/>
				</>
			)}

			{loading && (
				<div className="loading">
					<i className="bi bi-hourglass-split" />
				</div>
			)}

			{err && (
				<div className="error" >
					<i className="bi bi-slash-circle-fill" />
					<p>
						ビデオが見つかりません。
						管理会社へご連絡ください。
					</p>
				</div>
			)}
		</div>
	);
});

export default Player;
